<template>
  <div class="">
    <!-- 课程名称 -->
    <div class="subjectStyle" v-show="subject">{{ courseData.name }}</div>
    <div class="head">
      <h1 style="color: #1b84d0;">机位号</h1>
      <h1 style="color: #f76f21;">{{ formMsg.seatNumber }}</h1>
    </div>
    <div class="trunk" v-if="!subject">
      <el-row :gutter="20">
        <el-col :span="12" :offset="6">
          <div class="grid-content bg-purple">
            <el-form :model="form" label-width="100px" style="width: 500px;">
              <el-form-item label="准考证号：">
                <el-input v-model="form.examinationNumber" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="证件号码：">
                <el-input v-model="form.certificateNumber" autocomplete="off"></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button class="btn" type="primary" @click="login">登录</el-button>
              <el-button class="btn" type="warning" @click="reset">重置</el-button>
            </div>
          </div>

        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="6">
          <div class="bottom">
            <div style="color: #f76f21;">准考证号输入：1111111111111（14个1）</div>
            <div style="color: #f76f21;">证件号码输入：11111111111111111（18个1）</div>
            <div style="color: #f76f21;">备注：界面仅供参考</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="trunk" v-else>
      <el-form :model="formMsg" label-width="100px">
        <el-row class="msg">
          <el-col :span="8">
            <!-- <el-upload class="avatar-uploader" action="//jsonplaceholder.typicode.com/posts/" :show-file-list="false"
              :on-success="handleAvatarSuccess"> -->
            <!-- <i style="width: 100px; height: 80px; display: inline-flex; justify-content: center; align-items: center;"> -->
            <!-- <i class="el-icon-s-custom "></i> -->
            <el-avatar shape="square" :size="205" :src="squareUrl"></el-avatar>
            <!-- </i> -->
            <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon el-icon-s-custom "></i> -->
            <!-- </el-upload> -->
          </el-col>
          <el-col :span="16">
            <el-form-item label="准考证号" prop="examNumber" class="left">
              <el-input disabled v-model="formMsg.examNumber"></el-input>
            </el-form-item>
            <el-form-item label="姓名" prop="name" class="left">
              <el-input disabled v-model="formMsg.name"></el-input>
            </el-form-item>
            <el-form-item disabled label="性别" prop="gender" class="left">
              <el-radio-group v-model="formMsg.gender">
                <el-radio label="male">男</el-radio>
                <el-radio label="female">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="考试科目" prop="subject" class="left">
              <el-input disabled v-model="item.name"></el-input>
            </el-form-item>
            <el-form-item label="证件号码" prop="idNumber" class="left">
              <el-input disabled v-model="formMsg.idNumber"></el-input>
            </el-form-item>
            <el-form-item label="座位号" prop="seatNumber" class="left">
              <el-input disabled v-model="formMsg.seatNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" style="text-align: center;;">
        <el-button class="btn" type="primary" @click="submit">确认登陆</el-button>
        <el-button class="btn" type="warning" @click="back">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import practiceLogin from "./practiceLogin.vue";

export default {
  name: 'loginInterface',
  components: { practiceLogin },
  data() {
    return {
      subject: false,
      squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      form: { // 准考证信息
        examinationNumber: '1111111111111', // 准考证号
        certificateNumber: '11111111111111111', // 证件号码
      },
      formMsg: { // 学生信息表
        examNumber: '',
        name: '',
        gender: '',
        subject: '',
        idNumber: '',
        seatNumber: '111',
      },
      imageUrl: '',
      // subjectId: '',// 题目id
      item: {}, // 获取路由传递过来的数据
      courseData: {}, // 课程信息
    }
  },
  mounted() {
    this.courseData = this.$route.query.courseData
    this.formMsg.subject = this.courseData.name
    this.item = this.$route.query.item
    console.log(this.courseData);
    console.log(this.item);

    // 从本地会话存储中获取 name 和 sex 变量
    this.formMsg.name = window.localStorage.getItem('name');
    let sex = window.localStorage.getItem('sex');
    sex == '1' ? this.formMsg.gender = 'male' : this.formMsg.gender = 'female'
  },
  methods: {
    // 准考证号登录
    login() {
      this.subject = true
      this.formMsg.examNumber = this.form.examinationNumber
      this.formMsg.idNumber = this.form.certificateNumber
    },
    reset() {
      this.form.examinationNumber = ''
      this.form.certificateNumber = ''
    },
    // 信息
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    submit() {
      console.log(this.formMsg);

      this.$message.success('登陆成功')
      this.$router.push({
        path: '/answerPage',
        query: {
          item: this.$route.query.item,
          form: this.formMsg
        }
      })
      this.$emit('id', this.item.id)

    },
    back() {
      // this.$router.back()
      // this.$router.push('/loginInterface')
      this.subject = false
    },
  },
}
</script>

<style scoped>
.subjectStyle {
  text-align: center;
  font-size: 31px;
  color: #1b84d0;
  font-weight: 800;
  margin-bottom: -20px;
}

.head {
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 3em;
  margin: 0.67em 50px;
}

.el-col-offset-6 {
  margin-left: 34%;
}

.bg-purple {
  /* background: #d3dce6; */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3), -2px -2px 4px rgba(0, 0, 0, 0.3), 2px -2px 4px rgba(0, 0, 0, 0.3), -2px 2px 4px rgba(0, 0, 0, 0.3);
}

.grid-content {
  border-radius: 6px;
  min-height: 200px;
  text-align: center;
  display: table;
  padding: 35px;
}

.btn {
  width: 150px;
  margin: 20px 50px;
}

.bottom {
  margin: 40px 80px;
  line-height: 24px;
}

/* 信息 */
.msg {
  width: 700px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3), -2px -2px 4px rgba(0, 0, 0, 0.3), 2px -2px 4px rgba(0, 0, 0, 0.3), -2px 2px 4px rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin: auto;
  border-radius: 6px;
}


.avatar-uploader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.left {
  text-align-last: left;
}
</style>
